import React from 'react';
import { Box, Typography } from '@mui/material';
import Colors from 'assets/Style/Colors';
import moment from 'moment';

function Footer() {

	return (
		<Box sx={{ width: { xs: 'auto', md: '400px' }, mx: 'auto', bgcolor: Colors.white + 'e6', p: 1.5, mt: 2, borderRadius: 1, boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 4px' }}>
			<Typography variant="body1" sx={{ color: Colors.black, textAlign: 'center' }}>
				copyright © karachi Golf Club {moment().format('YYYY')}
			</Typography>
		</Box>
	);
}

export default Footer;