import React, { useEffect, useState } from 'react';
import { Box, Container, IconButton } from '@mui/material';
import { Images } from 'assets';
import { Close, Menu } from '@mui/icons-material';
import Colors from 'assets/Style/Colors';
import Sidebar from '../Sidebar';
import { useLocation } from 'react-router-dom';

function Header({ showSideDrawer = true }) {

	const { pathname } = useLocation();

	// *For Handle Side Drawer
	const [openDrawer, setOpenDrawer] = useState(false);

	useEffect(() => {
		setOpenDrawer(false);
	}, [pathname]);

	return (
		<Box sx={{ py: 1.5, position: 'relative' }}>
			<Container maxWidth="false" sx={{ textAlign: 'center' }}>
				<img src={Images.logo} alt='Karachi Golf Club' height="90px" width="auto" className='animated-logo' />

				{showSideDrawer &&
					<IconButton
						onClick={() => setOpenDrawer(!openDrawer)}
						sx={{
							borderRadius: 1,
							bgcolor: Colors.tuna + '80',
							position: "absolute",
							right: '20px',
							top: '20px',
							zIndex: '10000',
							'&:hover': {
								bgcolor: Colors.tuna,
							}
						}}
					>
						{openDrawer ? (
							<Close sx={{ fontSize: '34px', color: Colors.white }} />
						) : (
							<Menu sx={{ fontSize: '34px', color: Colors.white }} />
						)}
					</IconButton>
				}

				<Sidebar openDrawer={openDrawer} />

			</Container>
		</Box>
	);
}

export default Header;