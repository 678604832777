import React from 'react';
import { Box, Container } from '@mui/material';
import { Images } from 'assets';

function Header() {

  return (
    <Box sx={{ py: 1.5 }}>
      <Container maxWidth="false" sx={{ textAlign: 'center' }}>
        <img src={Images.logo} alt='Karachi Golf Club' height="90px" width="auto" className='animated-logo' />
      </Container>
    </Box>
  );
}

export default Header;