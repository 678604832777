import React, { Suspense } from 'react';
import { Box, Container } from "@mui/material";
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { CircleLoading } from 'components/Loader';
import { Images } from 'assets';

function DashboardLayout() {

  return (
    <Box sx={{ backgroundImage: `url(${Images.authBanner})`, width: 1, minHeight: '100vh', height: '100%', backgroundSize: 'cover', }}>

      {/* ========== Header ========== */}
      <Header />

      {/* ========== Main ========== */}
      <Box component="main">
        <Container>
          <Suspense fallback={<CircleLoading />}>
            <Outlet />
          </Suspense>
        </Container>
      </Box>

      {/* ========== Footer ========== */}
      <Footer />

    </Box>
  )
}

export default DashboardLayout