import React, { lazy } from 'react';

const Login = lazy(() => import('pages/Auth/Login'));

const AuthRoutes = [
  {
    path: "/auth/login",
    component: <Login />,
  },
]

export default AuthRoutes
