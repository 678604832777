const Colors = {
  primary: '#1D809F',
  secondary: '#05A363',
  textPrimary: '#B1B4B9',
  textSecondary: '#8c8c8c',
  tuna: '#343a40',
  gunMetal: '#263238',
  black: '#222222',
  white: '#ffffff',
  mercury: "#DFE5EB",
  cloud: "#c2c2c2",
  smokeyGrey: '#747474',
  desertStorm: '#f7f7f7',
  ghostWhite: '#F5F8FC ',
  flashWhite: '#EFF0F6',
  danger: '#FF2121',
  success: '#79AA5A',
  pending: '#0185BA',
};

export default Colors;